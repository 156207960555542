<template>
  <b-table-simple
    hover
    striped
    responsive
    class="rounded-bottom mb-0 mt-0"
  >
    <b-thead head-variant="light">
      <b-tr>
        <b-th>Product</b-th>
        <b-th>MRP [MYR]</b-th>
        <b-th>Amount [MYR]</b-th>
        <b-th>Total Txn</b-th>
        <b-th>Profit</b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <template v-if="list.length>0">
        <b-tr
          v-for="(tr, indextr) in list"
          :key="indextr"
        >
          <b-td>
            {{ tr.operator_name }}
          </b-td>
          <b-td>
            {{ (tr.transections.length>0)?tr.transections[0].org_amt:0.00 }}
          </b-td>
          <b-td>
            {{ (tr.transections.length>0)?tr.transections[0].amt:0.00 }}
          </b-td>
          <b-td>
            {{ (tr.transections.length>0)?tr.transections[0].tr_count:0.00 }}
          </b-td>
          <b-td>
            {{ (tr.transections.length>0)?tr.transections[0].profit:0.00 }}
          </b-td>
        </b-tr>
      </template>
      <template v-else>
        <b-tr rowspan="10">
          <b-td
            colspan="5"
            class="text-center"
          >
            No data avilable
          </b-td>
        </b-tr>
      </template>
    </b-tbody>
  </b-table-simple>
</template>
<script>
import {
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
} from 'bootstrap-vue'

export default {
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
  },
  props: ['data'],
  data() {
    return {
      operatorData: [],
    }
  },
  computed: {
    list() {
      this.operatorData = []
      const self = this
      this.data.map((value, key) => {
        if (value.transections.length > 0) {
          self.operatorData.push(value)
        }
      })
      return this.operatorData
    },
  },
}

</script>
