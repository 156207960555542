<template>
  <div>
     <b-alert
      v-model="showMessage"
      variant="success"
      dismissible
    >
      <div class="alert-body">
        <span>{{ message }}</span>
      </div>
    </b-alert>
    <b-card-actions
      ref="cardAction"
      title="Filters"
      @refresh="resetColFilters"
      @remove="resetColFilters"
    >
      <b-row>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>From date</label>
          <b-form-datepicker
            id="example-datepicker"
            v-model="dateFromFilter"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: '2-digit'}"
            locale="en"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>To date</label>
          <b-form-datepicker
            id="example-datepicker2"
            v-model="dateToFilter"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="en"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Select Downline User</label>
          <v-select
            v-model="loginid"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="userOptions"
            class="w-100"
            placeholder="Select Downline User"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2 mt-2"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon"
            @click="handleSearch"
          >Search
            <feather-icon icon="SearchIcon" />
          </b-button>
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2 mt-2"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon"
            v-b-modal.report-email
          >
            Export
          </b-button>
        </b-col>
      </b-row>
    </b-card-actions>
    <b-row class="match-height">
      <b-col md="6">
        <b-card>
          <b-card-title>Sales Chart</b-card-title>
          <b-overlay
            variant="white"
            :show="showeLoading"
            spinner-variant="primary"
            blur="0"
            opacity=".28"
            rounded="sm"
          >
            <e-charts
              ref="pie"
              autoresize
              :options="pie"
              theme="theme-color"
              auto-resize
            />
          </b-overlay>
        </b-card>
      </b-col>
      <b-col
        md="6"
        class="mb-md-0 mb-2"
      >
        <b-card>
          <b-card-title>Sales Report</b-card-title>
           <b-overlay
            variant="white"
            :show="saleLoading"
            spinner-variant="primary"
            blur="0"
            opacity=".28"
            rounded="sm"
          >
          <b-table-simple
            hover
            striped
            bordered
            responsive
            class="rounded-bottom mb-0"
          >
            <b-tbody>
              <b-tr>
                <b-td>Sales (MYR)</b-td>
                <b-td>{{ total_sale }}</b-td>
              </b-tr>
              <b-tr>
                <b-td>Profit</b-td>
                <b-td>{{ total_profit }}</b-td>
              </b-tr>
              <b-tr>
                <b-td>Successful</b-td>
                <b-td>{{ total_success }}</b-td>
              </b-tr>
              <b-tr>
                <b-td>Failed</b-td>
                <b-td>{{ total_failed }}</b-td>
              </b-tr>
              </b-tr>
              <b-tr>
                <b-td>Pending</b-td>
                <b-td>{{ total_pending }}</b-td>
              </b-tr>
              </b-tr>
              <b-tr>
                <b-td>Downline User</b-td>
                <b-td>{{ total_user }}</b-td>
              </b-tr>
              </b-tr>
              <b-tr>
                <b-td>User Payout</b-td>
                <b-td>{{ total_payout }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-overlay>
        </b-card>
      </b-col>
    </b-row>
    <b-card ref="operatorcard">
      <!--  <b-overlay
                    variant="white"
                    :show="showOpertorWiseLoading"
                    spinner-variant="primary"
                    blur="0"
                    opacity=".28"
                    rounded="sm"
                  > -->
      <b-card-title>Summary Report</b-card-title>
      <div
        v-show="showOpertorWiseLoading"
        class="text-center text-primary my-2"
      >
        <b-spinner class="align-middle" />
        <strong>This will only take a moment. Please wait...</strong>
      </div>
      <template
        v-for="(operatorType,name) in summaryList"
        v-if="!showOpertorWiseLoading"
      >
        <b-card>
          <b-card-title>{{ name }}</b-card-title>
          <b-tabs
            vertical
            nav-wrapper-class="nav-vertical"
          >
            <template v-for="(status,statusName) in operatorType">
              <b-tab :title="statusName">
                <b-card-text>
                  <transection :data="status" />
                </b-card-text>
              </b-tab>
            </template>
          </b-tabs>
        </b-card>
      </template>
      <!-- </b-overlay> -->
    </b-card>
    <b-row>
      <b-col md="6">
        <b-card>
          <b-card-title> Debit Notes</b-card-title>
          <b-table-simple
            hover
            striped
            bordered
            responsive
            class="rounded-bottom mb-0"
          >
            <b-thead head-variant="light">
              <b-tr>
                <b-th>Count</b-th>
                <b-th>MRP [MYR]</b-th>
                <b-th>Amount [MYR]</b-th>
                <b-th>Type</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <template v-if="debitNoteList.length>0">
                <b-tr
                  v-for="(tr, indextr) in debitNoteList"
                  :key="indextr"
                >
                  <b-td>
                    {{ tr.tr_count }}
                  </b-td>
                  <b-td>
                    {{ tr.org_amt }}
                  </b-td>
                  <b-td>
                    {{ tr.amt }}
                  </b-td>
                  <b-td>
                    {{ tr.type }}
                  </b-td>
                </b-tr>
              </template>
              <template v-else>
                <b-tr rowspan="10">
                  <b-td
                    colspan="4"
                    class="text-center"
                  >
                    No data avilable
                  </b-td>
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <b-card-title> E-Transfers</b-card-title>
          <b-table-simple
            hover
            striped
            bordered
            responsive
            class="rounded-bottom mb-0"
          >
            <b-thead head-variant="light">
              <b-tr>
                <b-th>Count</b-th>
                <b-th>MRP [MYR]</b-th>
                <b-th>Amount [MYR]</b-th>
                <b-th>Type</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <template v-if="transferList.length>0">
                <b-tr
                  v-for="(tr, indextr) in transferList"
                  :key="indextr"
                >
                  <b-td>
                    {{ tr.tr_count }}
                  </b-td>
                  <b-td>
                    {{ tr.org_amt }}
                  </b-td>
                  <b-td>
                    {{ tr.amt }}
                  </b-td>
                  <b-td>
                    {{ tr.type }}
                  </b-td>
                </b-tr>
              </template>
              <template v-else>
                <b-tr rowspan="10">
                  <b-td
                    colspan="4"
                    class="text-center"
                  >
                    No data avilable
                  </b-td>
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </b-col>
    </b-row>
    <validation-observer
      ref="emailreport"
      tag="form"
    >
    <b-modal
      id="report-email"
      modal-class="modal-success"
      centered
      title="Report Send To Email"
    >
      <b-card-text>
       <label>Email</label>
        <validation-provider
          #default="{ errors }"
          name="Email"
          rules="required|email"
        >
          <b-form-input
            id="email"
            v-model="other_email"
            placeholder="Enter Email"
             :class="errors.length > 0 ? 'is-invalid':null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-card-text>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button size="sm" variant="success" @click="exportReport">
        Send
      </b-button>
      </template>

    </b-modal>

    </validation-observer>
  </div>
</template>
<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/pie'
import theme from '@core/components/charts/echart/theme.json'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BTableSimple,
  BCard,
  BCardHeader,
  BCardBody,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BFormDatepicker,
  BRow,
  BCol,
  BButton,
  BBadge,
  BOverlay,
  BTabs,
  BTab,
  BFormInput,
  BModal,
  BCardText,
  BCardTitle,
  BAlert,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import UserService from '../../services/user.service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UserReportService from '../../services/user.report.service'
import { required, email } from '@validations'
import { ValidationProvider, ValidationObserver} from 'vee-validate'
import Transection from './summary_component/transection.vue'

ECharts.registerTheme('theme-color', theme)

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ECharts,
    BTableSimple,
    BFormDatepicker,
    BCard,
    BCardHeader,
    BCardBody,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    vSelect,
    BRow,
    BCol,
    BButton,
    BBadge,
    BFormInput,
    BOverlay,
    BCardActions,
    BTabs,
    BTab,
    BCardText,
    BCardTitle,
    BSpinner,
    BAlert,
    BModal,
    ToastificationContent,
    Transection,
  },
  directives: {
    Ripple,
  },
  data() {
    const data = []
    return {

      dateFromFilter: null,
      dateToFilter: null,
      showOpertorWiseLoading: false,
      showeLoading: false,
      saleLoading:false,
      required,
      email,
      total_sale: 0,
      total_success: 0,
      total_profit: 0,
      total_failed: 0,
      total_pending: 0,
      total_user: 0,
      total_payout: 0,
      date: '',
      userOptions:[],
      other_email:'',
      loginid:"",
      selected: [],
      status: [
        'Failed',
        'InProcess',
        'Online Pin Issue',
        'Success',
      ],
      ranksqlrowarray: ['InProcess', 'Refunded', 'Success'],
      prepaid: [],
      postpaid: [],
      games: [],
      pin: [],
      government: [],
      product_registration: [],
      itemsPerPage: 25,
      isMounted: false,
      addNewDataSidebar: false,
      reloadCount: [],
      issuePinCount: '',
      debitCount: '',
      transferCount: '',
      showMessage: false,
      message: '',
      summary: [],
      success: [],
      inprocess: [],
      pie: {
        tooltip: {
          trigger: 'item',
          formatter(params) {
            return `${params.seriesName}<br />
                    ${params.name}: ${params.data.value} (${params.percent}%)<br />
                    ${params.data.name1}: ${params.data.value1}<br />
                    ${params.data.name2}: ${params.data.value2}`
          },
        },
        legend: {
          left: 10,
          bottom: '0',
          data: ['Success', 'InProcess', 'Online Pin Issue', 'Failed'],
        },
        series: [{
          name: 'Transections',
          type: 'pie',
          radius: '55%',
          color: ['#EA5455', '#FF9F43', '#7367F0', '#28C76F'],
          data: [],
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        }],
      },
    }
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    listSuccess() {
      return this.success
    },
    listRefund() {
      return this.refunded
    },
    listInprocess() {
      return this.inprocess
    },
    reloadList() {
      return this.reloadCount
    },
    debitNoteList() {
      return this.debitCount
    },
    transferList() {
      return this.transferCount
    },
    queriedItems() {
      return this.totalItems
    },
    summaryList() {
      return this.summary
    },
    totalCredited() {
      return this.summary.reduce((total, item) => {
        if (item.type == 'C') {
          return total + parseInt(item.org_amt)
        }
        return total
      }, 0)
    },
    totalDebited() {
      return this.summary.reduce((total, item) => {
        if (item.type == 'D') {
          return total + parseInt(item.org_amt)
        }
        return total
      }, 0)
    },
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768
    },
  },
  mounted() {
    this.isMounted = true
    this.getDownlineUserList({'showUser':true})
    this.getReloadCount()
    this.getDebitNoteCount()
    this.getTransferCount()
    this.getSummaryInfo()
    this.getSale()
  },
  methods: {
    getColor(value) {
      if (parseInt(value) == 0) {
        return '#999'
      } if (parseInt(value) > 0) {
        return 'success'
      }
    },
    getStatusColor(status) {
      if (status == 'Success') return 'success'
      if (status == 'Transfered') return 'success'
      if (status == 'Refunded') return 'warning'
      if (status == 'InProcess') return 'warning'
      if (status == 'Failed') return 'danger'
      if (status == 'Online Pin Issue') return '#7367F0'
      return 'success'
    },
    changeLenght(data) {
      this.itemsPerPage = data
      this.getSummaryInfo({
        limit: this.itemsPerPage,
      })
    },
    handleSearch() {
      if (this.dateFromFilter && this.dateToFilter) {
        this.date = `${this.dateFromFilter}:${this.dateToFilter}`
      }
      // this.$emit('search-summary',this.date)
      this.getSummaryInfo({
        date: this.date,
        loginid:this.loginid
      })
      this.getReloadCount({
        date: this.date,
        loginid:this.loginid
      })
      this.getDebitNoteCount({
        date: this.date,
        loginid:this.loginid
      })
      this.getTransferCount({
        date: this.date,
        loginid:this.loginid 
      })
    this.getSale({date: this.date,
        loginid:this.loginid})
      // this.$refs.prepaid.getInfo(this.date);
    },
    handleChangePage(page) {
      this.getSummaryInfo({
        page: this.userData.current_page,
        date: this.date,
        loginid:this.loginid,
      })
    },
    handleSort(key, active) {},
    resetColFilters() {
      this.dateFromFilter = null,
      this.dateToFilter = null,
      this.loginid = '';
      // this.getSummaryInfo();
      this.getReloadCount()
      this.getDebitNoteCount()
      this.getTransferCount()
      this.getSale()
      this.$refs.cardAction.showLoading = false
    },
    getSummaryInfo(data) {
      this.showOpertorWiseLoading = true
      UserReportService.getSummaryInfo(data).then(
        response => {
          this.summary = response.summary
          this.showOpertorWiseLoading = false
        },
        error => {
          this.showOpertorWiseLoading = false
        },
      )
    },
     getDownlineUserList(data) {
      this.showLoading = true
      UserService.getDownlineUsers(data).then(
        response => {
           const self = this
          response.DownlineUsers.map((value, key) => {
            self.userOptions.push(
              { value: value.loginid, label: value.loginid+' ('+value.name+')' },
            )
          })
        },
        error => {
          this.showLoading = false
        },
      )
    },
    getSale(data) {
      this.saleLoading = true
      UserReportService.getTotalTransectionByDate(data).then(
        response => {
          this.total_sale = response.total_sale
          this.total_profit = response.total_profit
          this.total_success = response.total_success
          this.total_failed = response.total_failed
          this.total_pending = response.total_pending
          this.total_user = response.total_user
          this.total_payout = response.total_payout
          this.saleLoading = false
        },
        error => {},
      )
    },
    getReloadCount(data) {
      this.showeLoading = true
      UserReportService.getReloadCount(data).then(
        response => {
          this.reloadCount = response.reloadCount

          this.pie.series[0].data = []
          const self = this
          this.status.map((value, key) => {
            self.pie.series[0].data.push(
              {
                value: response[value].tr_count, name: value, name1: 'Profit', value1: response[value].profit, name2: 'Amount', value2: response[value].amt,
              },
            )
          })
          this.showeLoading = false
        },
        error => {
          this.showeLoading = false
        },
      )
    },
    getDebitNoteCount(data) {
      UserReportService.getDebitNoteCount(data).then(
        response => {
          this.debitCount = response.debitNoteCount
        },
        error => {},
      )
    },
    getTransferCount(data) {
      UserReportService.getTransferCount(data).then(
        response => {
          this.transferCount = response.transferCount
          this.dateFromFilter = response.fromdate
          this.dateToFilter = response.todate
        },
        error => {},
      )
    },
     exportReport() {
      if (this.dateFromFilter && this.dateToFilter) {
        this.date = `${this.dateFromFilter}:${this.dateToFilter}`
        this.$refs.emailreport.validate().then(success => {
          if (success) {
            UserReportService.exportSummaryInfo({
              date: this.date,
              loginid:this.loginid,
            }).then(
              response => {
                this.showMessage = true
                this.message = response.message
              },
              error => {
              },
            )
          }
        })  
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: 'Please Select To and from date',
            icon: 'MessageSquareIcon',
            variant: 'danger',
          },
        })
      }
    },
  },

};

</script>
<style lang="scss" scoped>
@import '~@core/scss/vue/libs/chart-apex.scss';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.echarts {
    width: 100% !important
}

.card {
    ::v-deep .card-header {
        .heading-elements {
            position: static;
            cursor: inherit;

            .list-inline {
                display: block;

                li {
                    a {
                        padding: 0;
                    }

                    &:not(:last-child) {
                        margin-right: 1rem;
                    }
                }
            }
        }
    }
}

.dark-layout {
    .b-overlay-wrap ::v-deep .b-overlay {

        // border: 10px solid red;
        .bg-white {
            background-color: $theme-dark-body-bg !important;
        }
    }
}

</style>
